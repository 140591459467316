import "./FoldBottom.css";

function FoldBottom() {
  return (
    <div className="FoldBottom">
      <div className="fold-bottom-headline">Need to Sell Your House Fast?</div>
      <div className="fold-bottom-text">
        Home Selling Simplified. No repairs. No Fees. No Commissions. No Closing
        Costs
      </div>
    </div>
  );
}

export default FoldBottom;
